import React from 'react';
import './App.css';
import InteractiveComponent from './components/InteractiveComponent.js'
import ChatBoxComponent from './components/ChatBoxComponent.js'
import { withAuthenticator } from '@aws-amplify/ui-react';

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
        };
        this.handleWindowSizeChange = () => {
            this.setState({ width: window.innerWidth });
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    render() {
        const { width } = this.state;
        const isMobile = width <= 700;

        // TODO figure out a mobile layout
        var layout = isMobile ? "Vertical" : "Horizontal";

        return(
            <div className={layout}>
                <InteractiveComponent />
                <ChatBoxComponent />
            </div>
        )
    }
}

export default withAuthenticator(App);
import React from 'react';
import Talk from "talkjs";
import { v4 as uuid } from 'uuid';

import { Auth } from 'aws-amplify';

class ChatBoxComponent extends React.Component {

    constructor(props) {
        super(props);
        this.talkJsContainer = React.createRef();
        this.userId = Auth.user.attributes.sub
        this.name = Auth.user.attributes.name
        this.email = Auth.user.attributes.email
        console.log("User: " + this.name);
    }

    componentDidMount() {
        Talk.ready.then(() => {
            var me = new Talk.User({
                id: this.userId,
                name: this.name,
                email: this.email,
                role: "user"
            });

            window.talkSession = new Talk.Session({
                appId: "tkaXXZ4j",
                me: me
            });

            var eliza = new Talk.User({
                id: "000000",
                name: "Eliza",
                email: "eliza@talktoeliza.com",
                photoUrl: "https://talktoeliza-com-public.s3.us-west-2.amazonaws.com/eliza-4.jpg",
                welcomeMessage: "Hello and welcome. My name is Eliza.",
                role: "eliza"
            });

            var conversation = window.talkSession.getOrCreateConversation(Talk.oneOnOneId(me, eliza))
            conversation.setParticipant(me);
            conversation.setParticipant(eliza);

            var chatBox = window.talkSession.createChatbox(conversation);
            chatBox.mount(this.talkJsContainer.current);
        }).catch(e => console.error(e));
    }

    render() {
        return(
            <div className="ChatBoxComponent">
                <div className="TalkJs" ref={this.talkJsContainer} />
                <div className="EmojiBar">
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="happy">😊</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="grinning">😄</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="rofl">🤣</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="party face">🥳</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="heart">❤️</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="heart eyes">😍</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="hmm">🤔</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="eye roll">🙄</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="thumbs up">👍</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="thumbs down">👎</span></span>
                </div>
                <div className="EmojiBar">
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="sad">😔</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="crying">😢</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="sobbing">😭</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="frustrated">😫️</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="fire">🔥</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="party popper">🎉</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="dance">💃</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="taco">🌮</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="dog">🐶</span></span>
                    <span className="Emoji" onClick={handleEmojiClick(this)}><span role="img" aria-label="cat">🐱</span></span>
                </div>
            </div>
        )
    }
}

// TODO hmm click doesn't work but I'm terrible as JS so I want to spend my time elsewhere.
// The idea was to create clickable buttons for typing with a keyboard and mouse. Using an
// emoji keyboard with "recently used" support would be best.
function handleEmojiClick(button) {
    var textElement = document.getElementsByClassName("entry-field")[0];
    if(textElement != null && textElement.focused) {
        console.log("Found element with class entry-field")
        var currentVal = textElement.value;
        textElement.value = currentVal + button.value;
    } else {
        // do nothing
        console.log("Didn't find element with class entry-field")
    }
}

export default ChatBoxComponent;

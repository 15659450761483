import React from 'react';
import { AmplifySignOut } from '@aws-amplify/ui-react';

class AccountComponent extends React.Component {

    render() {
        return(
            <div className="AccountComponent" >
                <AmplifySignOut />
            </div>
        )
    }

}

export default AccountComponent;

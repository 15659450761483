import React from 'react';

import AccountComponent from './AccountComponent.js'

class InteractiveComponent extends React.Component {

    render() {
        return(
            <div className="InteractiveComponent" >
                <AccountComponent />
            </div>
        )
    }

}

export default InteractiveComponent;
